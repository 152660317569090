// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@/scss/variables.scss'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

import {
  VDataTable,
  VDataTableServer,
  VDataTableVirtual,
} from "vuetify/labs/VDataTable";

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {components: {
    VDataTable,
    VDataTableServer,
    VDataTableVirtual
  }}
)
