<template>
  <v-app>
    <google-map/>
  </v-app>
</template>


<script>
import GoogleMap from './components/GoogleMap.vue'

export default {
  name: 'App',

  components: {
    GoogleMap,
  },

  data: () => ({

  }),
}
</script>