<template>
  <div>
    <v-toolbar dark prominent class="toolbar">
      <img class="title-icon"
        v-bind:src="imgPath">
      <span class="toolbar-title">
        学区リサーチマップ
      </span>
      <v-spacer></v-spacer>
      <v-btn @click="getOshirase">お知らせ</v-btn>
      <v-btn id="manual_link" href="#" target="_blank">ご利用にあたって</v-btn>
      <v-btn id="provided_link" href="#" target="_blank">提供地域</v-btn>
      <v-btn id="source_link" href="#" target="_blank">データ出典</v-btn>

    </v-toolbar>
    
    <div class="map-content">
      <transition>
        <div
          id="map" class="map" ref="map"
          v-bind:style="[expand ? 'width:calc(100% - 260px)':'width:100%']"
        ></div>
      </transition>

      <!-- Loading画面 -->
      <v-overlay
       :model-value="isLoading"
       :persistent="true"
        class="align-center justify-center"
        >
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
     
      <!-- メニュー表示ボタン -->
      <div
        class="switch-menu-btn show"
          @click.stop="expand = !expand">
        <p>◀</p>
      </div>
      
      <!-- メニュー本体 -->
      <transition>
        <div
          class="switch-menu"
          v-bind:style="[expand ? 'width:260px':'width:0px']">
          <v-sheet class="switch-menu-area1">
            <v-row class="switch-menu-area2" no-gutters justify="center" @click.stop="expand = !expand">
              <v-col cols="10" >
                <p class="switch-menu-title">メニューを隠す</p>
              </v-col>
              <v-col cols="2">
                <p class="switch-menu-btn hide">▶</p>
              </v-col>
            </v-row>
            <div class="switch-menu-scroll" >
              <p class="switch-menu-subtitle1">学区を切り替える</p>
              <v-radio-group v-model="schoolzone" column @change="onSchoolChange">
                <v-radio value="es">
                  <template v-slot:label>
                    <p class="radio-item-label">小学校区を表示する</p>
                  </template>
                </v-radio>
                <v-radio value="jhs">
                  <template v-slot:label>
                    <p class="radio-item-label">中学校区を表示する</p>
                  </template>
                </v-radio>
                <v-radio value="none">
                  <template v-slot:label>
                    <p class="radio-item-label">表示しない</p>
                  </template>
                </v-radio>
              </v-radio-group>
              <div class="legends-block1" v-show="legendsControl">
                <v-row class="legends-block2">
                  <p class="legends-title">【凡例】</p>
                  <v-divider class="border-opacity-30 ml-n2"></v-divider>
                  <v-row class="legends-es-margin" dense justify="center" v-show="legendsControlEs">
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <img class="legends-icon" src="/poi/school/icon_primary_school.png">
                        <div class="align-self-center">
                          <p class="legends-icon-label">小学校</p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <img class="legends-icon" src="/poi/school/icon_selected.png">
                        <div class="align-self-center">
                          <p class="legends-icon-label">選択中の学校</p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-es info-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">情報提供地域</p>
                          </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-es selected-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">選択中の通学区域</p>
                          </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-es unknown-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">学校選択制・不確実な地域</p>
                          </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="legends-jhs-margin" dense justify="center" v-show="legendsControlJhs">
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <img class="legends-icon"
                          src="/poi/school/icon_junior_high_school.png">
                        <div class="align-self-center">
                          <p class="legends-icon-label">中学校</p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <img class="legends-icon"
                          src="/poi/school/icon_selected.png">
                        <div class="align-self-center">
                          <p class="legends-icon-label">選択中の学校</p>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-jhs info-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">情報提供地域</p>
                          </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-jhs selected-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">選択中の通学区域</p>
                          </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex flex-row">
                        <div class="rectangle-jhs unknown-area"></div>
                          <div class="align-self-center">
                            <p class="legends-label">学校選択制・不確実な地域</p>
                          </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="border-opacity-30 ml-n2"></v-divider>
                  <p class="legends-annotaion">{{legendsComment}}</p>
                </v-row>
              </div>
            </div>
          </v-sheet>
        </div>
      </transition>

      <!-- 検索 -->
      <div id="search" ref="search" v-show="customCtrl" class="search-window">
        <v-card
          color="white"
          max-width="400">
            <v-form @submit.prevent="formResetKey++" autocomplete="on" :key="formResetKey">
              <v-text-field
                density="compact"
                variant="solo"
                append-inner-icon="mdi-magnify"
                hide-details
                placeholder="フリーワード検索"
                v-model="search_message"
                @click:append-inner="geocoding"
                @keypress.enter="geocoding"
                name="search"
                autocomplete="on"
                class="search-textfield"
              ></v-text-field>
            </v-form>
        </v-card>
      </div>

      <!-- 注釈枠 -->
      <v-row aline="center" justify="center" gutters>
        <div
          id="annotation"
          v-show="annotationCtrl"
          class="annotation-window">
          <v-card color="white" dark class="text-body text-red mx-6 mt-3">
            <v-row justify="end">
              <div v-on:click="annotationCtrl = false" class="annotation-closebtn-area">
                <p class="annotation-closebtn">×</p>
              </div>  
            </v-row>
            <v-card-text class="annotation-text">{{annotation}}</v-card-text>
          </v-card>
        </div>

        <!-- 縮尺エラー -->
        <div id="alertmsg" class="scale-error-window">
          <v-card
            v-show="alertmsg">
            <v-row justify="end" class="scale-error-closebtn-area">
              <v-col cols="11"></v-col>
              <v-col cols="1" v-on:click="alertmsg = false" class="scale-error-closebtn-pointer">
                <p class="scale-error-closebtn-size">×</p>
              </v-col>
            </v-row>
            <v-col cols="12" class="scale-error-msg-area">
              <v-card-text class="scale-error-msg">{{alertMsg}}</v-card-text>
            </v-col>
          </v-card>
        </div>
      </v-row>

      <!-- API error -->
      <v-dialog v-model="apiError" width="auto">
        <v-card class="api-error-area" v-click-outside="closeApiError">
          <v-card-text class="py-0">
            <v-row aline="center" no-gutters>
              <v-col cols="1">
                <v-icon class="text-left" color="red" icon="mdi-alert" size="36"></v-icon>
              </v-col>
              <v-col cols="11" class="api-error-msg-area">
                <p class="api-error-msg">{{apiErrorMessage}}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- 利用要領 -->
      <modal
        :kiyakuTitle="kiyakuTitle"
        :kiyakuSubtitle="kiyakuSubtitle"
        :kiyakuMessage="kiyakuMessage"
        v-show="modal"
        @initial-method="initialMethod"
      ></modal>

      <!-- お知らせ一覧 -->
      <v-row justify="center">
        <v-dialog 
          v-model="oshiraseDialog" class="oshirase-list" persistent>
          <v-row justify="end">
            <v-btn rounded class="oshirase-close-btn" @click="closeOshirase">× 閉じる</v-btn>
          </v-row>
          <v-card width="100%" height="550px">
            <v-card-title class="oshirase-title">{{ oshiraseTitle }}</v-card-title>

            <!-- お知らせリスト表示 -->
            <div v-show="oshiraseContentsList" class="oshirase-area-scroll">
              <v-table>
                <colgroup>
                  <col name="date" width="160">
                  <col name="title" width="calc(100% - 160 - 200)">
                  <col name="button" width="200">
                </colgroup>
                <tbody>
                  <tr
                    v-for="item in oshiraseData"
                      :key="item.oshirase_date"
                    class="oshirase-record">
                    <td>
                      <div class="oshirase-date">{{ item.oshirase_date }}</div>
                    </td>
                    <td v-html="item.oshirase_title"></td>
                    <td>
                      <v-btn variant="flat" class="oshirase-btn" @click="getshosai(item)">詳しくはこちら</v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>

            <!-- お知らせが0件の時 -->
            <div v-show="oshiraseContentsZero">
              <div class="oshirase-zero-area">
                <p class="oshirase-zero">
                  お知らせはありません
                </p>
              </div>
            </div>

            <!-- お知らせ詳細 -->
            <div v-show="oshiraseShosai">
              <v-card flat class="oshirase-area-scroll" height="430px">
                <v-card-text>
                  <p v-html="oshiraseShosaiMsg"></p>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-btn class="oshirase-btn back-list" @click="oshiraseBackToList">リストに戻る</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Loader } from '@googlemaps/js-api-loader';
import Modal from "./Modal.vue";

axios.defaults.withCredentials = true;
const envLayerMinZoom = document.getElementById('layer_min_zoom').dataset.layerminzoom;
var LAYER_MIN_ZOOM = 13;
if (envLayerMinZoom >= 6 && envLayerMinZoom <= 18) {
  LAYER_MIN_ZOOM = parseInt(envLayerMinZoom, 10);
}

var map = null;
var eventListener = null;
var baseUrl = '';
var infowindowList = new Array();
var polygonList = new Array();
var schoolPointList = new Array();

export default {
  components: {
    Modal,
  },
  props: { 
  },
  data :function() {
    return {
        imgPath: require('@/assets/icon/title_icon_black.png'),
        kiyakuTitle: '学区リサーチマップ',
        kiyakuSubtitle: '利用要領',
        kiyakuMessage: `【学区リサーチマップ利用要領】

                        本要領には、学区リサーチマップ（以下「本サービス」といいます。）を利用するための条件等が定められています。本サービスを利用する方（以下「利用者」といいます。）は、本サービスの利用に際し、本要領に同意のうえご利用ください。

                        １．本サービスは、学校教育法第1条で定められている公立の小学校、中学校、義務教育学校の通学区域（学区）及び学校情報をスクロール地図上で閲覧できるものです。
                        アットホーム株式会社（以下「当社」といいます。）が運営する「アットホーム全国不動産情報ネットワーク」に加盟する方（以下「会員」といいます。）で、「加盟店専用サイト」のアカウントを持つ方は無償で本サービスを利用することができます。

                        ２．本要領は、「アットホーム全国不動産情報ネットワーク利用約款」の一部を構成するもので、利用者が本要領に違反した場合、「アットホーム全国不動産情報ネットワーク利用約款」に基づき会員から除名され、又は当社サービスの利用を制限されることがあります。なお、本要領の規定と「アットホーム全国不動産情報ネットワーク利用約款」の規定が相違する場合、本要領の規定が優先して適用されます。

                        ３．本サービスで閲覧可能な情報（以下「本件情報」といいます。）は、国土交通省が公表している国土数値情報及び各自治体が公表している情報をもとに、当社が作成・加工したものです。
                        利用者は、本サービスの利用にあたり、以下の各号に掲げる事項に同意するものとします。
                        (1)本件情報は、利用者自らの業務の参考のためにのみ利用し、通学区域（学区）及び学校の最新情報や実際の状況等は利用者の費用と責任において、自治体及び行政機関へ確認する必要があること
                        (2)当社が、本件情報について完全性、正確性、最新性、網羅性、有用性、有効性及び利用者の利用目的適合性を保証しないこと
                        (3)情報取得が困難等の理由から通学区域（学区）及び学校情報が整備されていない地域があること、及び地図精度上の誤差等のため、本件情報が実際の状況とは異なる場合があること
                        (4)本件情報は利用者への予告なく変更又は削除される場合があること
                        (5)本件情報及び本件情報作成の過程に当社独自のノウハウ、営業秘密等が含まれるなどの理由により、利用者の本件情報に関する質問に回答することができない場合があること
                        (6)当社が、本サービスが利用者の利用環境上で正常に動作することを保証しないこと

                        ４．本件情報の著作権は、当社又は当社に情報を提供した方（以下「情報提供者」といいます。）に帰属します。
                        当社は、本件情報の複製、改変、転用、転載、電磁的加工、送信、頒布及び二次的使用並びにこれらに類するすべての行為を禁止します。

                        ５．当社は、利用者に対しあらかじめ通知することなく、本サービスの提供を停止又は完全に終了することがあります。

                        ６．当社及び情報提供者は、利用者が本サービスを利用したこと又は利用できないことにより、利用者又は第三者に直接又は間接の損害、損失等が生じた場合であっても、一切の責任を負いません。


                        附則 本要領は2023年10月11日より適用します。`,
        modal: true,
        apiError: false,
        apiErrorMessage: `しばらく待ってから再度お試しください`,
        customCtrl: false,
        annotationCtrl: false,
        search_message: '',
        schoolzone: 'es',
        annotation: `本サービスで提供する情報（以下「本件情報」といいます。）は、国土交通省が公表している国土数値情報及び自治体等が公表している情報をもとに、アットホーム株式会社が作成・加工しております。
                      通学区域公表後の学区境界や就学校の変更等により、本件情報は必ずしも最新・正確でない場合があります。自治体等により、通学区域(学区)以外の学校選択が可能な場合(学校選択制等)があります。
                      本件情報はご参考としてご利用いただき、最新情報や詳細については自治体または教育委員会へお問い合わせください。`,
        alertmsg: true,
        alertMsg: `地図を拡大いただくことで学区情報が表示されます`,
        expand: true,
        tilesloaded: false,
        mapCreated:false,
        isLoading: false,
        legendsComment: `※自治体等から詳細な学区情報が提供されていない等の理由により、一部未対応の地域があります。あらかじめご了承ください。`,
        legendsControl: true,
        legendsControlEs: true,
        legendsControlJhs: false,
        oshiraseDialog: false,
        oshiraseContentsList:true,
        oshiraseContentsZero: false,
        oshiraseShosai: false,
        oshiraseData: [],
        oshiraseShosaiMsg: '',
        oshiraseTitle: 'お知らせ',
        formResetKey: 1,
        dispEmphasisTile: false,
    };
  },
  mounted() {
    var apiKey = document.getElementById('api_key').dataset.apikey;
    new Loader({
      apiKey: apiKey,
      version: '3.55',
      libraries: ["core","marker"],
      language: 'ja',
    })
    .load()
    .then((google) => {
      this.google = google;
      // 地図の初期化
      this.map = new google.maps.Map(document.getElementById('map'), {
        // 初期表示設定
        zoom : 6,
        minZoom: 6,
        maxZoom: 18,
        clickableIcons: false,
        gestureHandling: "greedy",
        center: { lat: 35.68125, lng: 139.76712 }, 
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_TOP,
          style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ["roadmap", "satellite"],
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP
        },
        keyboardShortcuts:false,
      });
      const searchControlDiv = document.getElementById("search");
      searchControlDiv.style.margin = '10px 0 10px 10px';
      this.map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(searchControlDiv);
      const annotationControlDiv = document.getElementById("annotation");
      annotationControlDiv.style.margin = '0px 0 25px 0px';
      this.map.controls[this.google.maps.ControlPosition.BOTTOM_CENTER].push(annotationControlDiv);
      const alertmsgControlDiv = document.getElementById("alertmsg");
      alertmsgControlDiv.style.margin = '150px 10px 10px 100px';
      this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(alertmsgControlDiv);

      let source_link = document.getElementById('source_link');
      let source_url = document.getElementById('source_url').dataset.sourceurl + "/schooldistrict-source.pdf";
      let manual_link = document.getElementById('manual_link');
      let manual_url = document.getElementById('source_url').dataset.sourceurl + "/manual.pdf";
      let provided_link = document.getElementById('provided_link');
      let provided_url = document.getElementById('source_url').dataset.sourceurl + "/provided-area.pdf";
      source_link.setAttribute('href', source_url);
      manual_link.setAttribute('href', manual_url);
      provided_link.setAttribute('href', provided_url);

      map = this.map
      const func = this.render;
      this.google.maps.event.addListenerOnce(this.map, 'tilesloaded', function() {
        func();
      });

      // ウィンドウの監視とセッションクッキーの監視を開始
      this.startObserveVisibility();
      setTimeout(this.observeSession, 1000);
    })
    .catch(e => {
      console.error(e);
    });
  },
  watch: {
    tilesloaded: function(val) {
      if (val === true) {
        this.$nextTick(() => {
            this.customCtrl = true;
            this.annotationCtrl = true;
        });
      }
    },
    modal: function(val) {
      if (val === false) {
        this.$nextTick(() => {
          this.mapCreated = true;
        });
      }
    },
    apiError: function(val) {
      if(val === false) {
        this.closeOshiraseError()
      }
      this.setInertWindow(val);
    }
  },
  methods: {
    startObserveVisibility() {
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") {
          // visibleに変化したら、セッションの監視を行う
          this.observeSession();
        }
      });
    },
    observeSession() {
      if (this.existSessionCookie('kkc_cds_session') == false)  {
        // location.reload();
        location.href = "/timeout";
      }
      if (document.visibilityState === "visible") {
          // visibleの時のみタイマーを設定する
          setTimeout(this.observeSession, 1000);
      }
    },
    existSessionCookie(key) {
      var cookies = document.cookie;
      var matchPos = cookies.indexOf(key);
      if (matchPos != -1) {
        return true;
      }
      return false;
    },
    render() {
      this.tilesloaded = true;
    },
    initialMethod() { 
      this.modal = false;
      this.setInertWindow(false);
      this.getSchoolzoneTile('es');
      this.getSchoolPointTile('es');
      this.addMapClickEvent('es');
      this.addZoomChangeEvent();
      this.getOshirase(null);
    },

    setInfoWindow(position, message) {
      var infowindow = new window.google.maps.InfoWindow();
      infowindowList.push(infowindow);
      infowindow.setContent(message);
      infowindow.setPosition(position);
      infowindow.open(map);
    },
    clearInfoWindow() {
      for(let i=0; i < infowindowList.length; i++) {
        infowindowList[i].close();
      }
    },
    setSchoolPoint(position, school) {
      var anchorPoint;
      var url;
      var origin = new window.google.maps.Point(0, 0);
      var anchor; 
      var scaledSize;
      if (school == 'es') {
        anchorPoint = new window.google.maps.Point(40, 40);
        url = "/poi/school/icon_selected.png";
        anchor = new window.google.maps.Point(20, 20);
        scaledSize = new window.google.maps.Size(40, 40);
      } else {
        anchorPoint = new window.google.maps.Point(40, 40);
        url = "/poi/school/icon_selected.png";
        anchor = new window.google.maps.Point(20, 20);
        scaledSize = new window.google.maps.Size(40, 40);
      }

      var schoolpoint = new window.google.maps.Marker({
        position: position,
        anchorPoint: anchorPoint,
        map: map,
        icon: {
          url: url,
          origin: origin,
          anchor: anchor,
          scaledSize: scaledSize,
        }
      });
      schoolPointList.push(schoolpoint);
      schoolpoint.setMap(map);

      // Polygonにクリックイベントを設定
      this.addOverlayClickEvent(school, schoolpoint)

    },
    clearSchoolPoint() {
      for(let i=0; i < schoolPointList.length; i++) {
        schoolPointList[i].setMap(null);
      }
    },
    setPolygon(coordinates, school, fitBounds) {
      // ポリゴンからfitBoundsに設定する値を取得する
      var min_lat, max_lat, min_lon, max_lon = 0;
      if (fitBounds.length == 0) { 
        min_lat = 0;
        max_lat = 0;
        min_lon = 0;
        max_lon = 0;
      } else {
        min_lat = fitBounds[0][1];
        max_lat = fitBounds[1][1];
        min_lon = fitBounds[0][0];
        max_lon = fitBounds[1][0];
      }
      // ドーナツ型と飛び地型のポリゴンでデータの持ち方が異なる
      var bDonut = false;
      var numPolygon;
      if (coordinates.length > 1) {
        // 飛び地型のポリゴン
        numPolygon = coordinates.length;
      } else {
        numPolygon = coordinates[0].length;
        // ポリゴンの数が1より大きければドーナツ型のポリゴン
        if (numPolygon > 1) bDonut = true;
      }
      var coords = coordinates;
      var polygonCoords = [];
      var vertexes;
      var numVertex
      for (let i=0; i<numPolygon; i++) {
        polygonCoords[i] = [];
        if (bDonut) {
          vertexes = coords[0][i];
          numVertex = coords[0][i].length;
        } else {
          vertexes = coords[i][0];
          numVertex = coords[i][0].length;
        }
        for (let j=0; j<numVertex; j++) {
          var lat = vertexes[j][1];
          var lon = vertexes[j][0];
          polygonCoords[i].push(new window.google.maps.LatLng(lat, lon));
          // fitBounds用の座標の値を取得
          if (min_lat == 0) min_lat = lat;
          if (max_lat == 0) max_lat = lat;
          if (min_lon == 0) min_lon = lon;
          if (max_lon == 0) max_lon = lon;
          if (lat < min_lat) min_lat=lat;
          if (lat > max_lat) max_lat=lat;
          if (lon < min_lon) min_lon=lon;
          if (lon > max_lon) max_lon=lon;

        }
      }
      fitBounds[0][1] = min_lat;
      fitBounds[1][1] = max_lat;
      fitBounds[0][0] = min_lon;
      fitBounds[1][0] = max_lon;

      var color;
      if (school == 'es') {
        color = "#df5656";
      } else {  /* jhs */
        color = "#df5656"
      } 

      var polygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: color,
        strokeOpacity: 0.25,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.25
      });
      polygonList.push(polygon);
      polygon.setMap(map);
     
      // Polygonにクリックイベントを設定
      this.addOverlayClickEvent(school, polygon)
      
    },
    clearPolygon() {
      for(let i=0; i < polygonList.length; i++) {
        polygonList[i].setMap(null);
      }
    },
    onSchoolChange(event) {   // ラジオボタンによる学区切替
      this.clearInfoWindow();
      this.clearPolygon();
      this.clearSchoolPoint();
      if (eventListener) window.google.maps.event.removeListener(eventListener);
      if (event.target.value == 'none') {
        this.legendsControl = false;
        map.setClickableIcons(true);
        this.alertmsg = false;
      } else {
        this.legendsControl = true;
        if (event.target.value == 'es') {
          this.legendsControlEs = true;
          this.legendsControlJhs = false;
        } else {
          this.legendsControlEs = false;
          this.legendsControlJhs = true;
        }
        map.setClickableIcons(false);
        if (map.getZoom() < LAYER_MIN_ZOOM) {
          this.alertmsg = true;
        }
      }
      map.overlayMapTypes.clear();
      this.dispEmphasisTile = false;
      this.getSchoolzoneTile(event.target.value);
      this.getSchoolPointTile(event.target.value);
      this.addMapClickEvent(event.target.value);
      axios.get('gakku/parea' + '?change=' + event.target.value)
        .then(response => {
          if (response.status != 200) {
            // this.openApiError(`しばらく待ってから再度お試しください`);
          }
        })
        .catch(function() {
          // this.openApiError(`しばらく待ってから再度お試しください`);
        }.bind(this));
    },
    setInertWindow(value) {
      let element = document.getElementById('app');
      element.inert = value;
    },
    closeApiError() {
      this.apiError = false;
    },
    openApiError(message) {
      this.isLoading = false;
      this.apiErrorMessage = message;
      this.apiError = true;
    },
    geocoding() {   // NGの時にダイアログにフォーカスする
      if (this.search_message.length < 1 ) {
        return;
      }
      var geo = new window.google.maps.Geocoder();
      this.isLoading = true;
      geo.geocode( { 'address': this.search_message}, function(results, status) {
        this.isLoading = false;
        if (status == 'OK') {
          map.setCenter(results[0].geometry.location);
          if (map.getZoom() < LAYER_MIN_ZOOM) {
            map.setZoom(LAYER_MIN_ZOOM);
          }
        } else if (status == "ZERO_RESULTS"){
          this.openApiError(` 検索結果がありませんでした`);
        } else {
          this.openApiError(`しばらく待ってから再度お試しください`);
       }
      }.bind(this));
    },
    addZoomChangeEvent() {  // 地図のzoomの変化イベント
      window.google.maps.event.addListener(map, 'zoom_changed', function() {
        if (this.schoolzone == 'none') return;
        if (map.getZoom() < LAYER_MIN_ZOOM) {
          this.alertmsg = true;
          return;
        }
        this.alertmsg = false;
      }.bind(this));
    },
    setEmphasisTile(schoolType, schoolCode = [], fitBounds = []) {

      const emphasisTile = new window.google.maps.ImageMapType({
        getTileUrl: function(coord, zoom){
          // if (zoom < LAYER_MIN_ZOOM) return;

          var pixelcoordX = coord.x * 512;
          var pixelcoordY = coord.y * 512;
          const scale = 1 << map.getZoom();
          const projection = map.getProjection();
          const worldCoordNW = new window.google.maps.Point(
            pixelcoordX / scale,
            pixelcoordY / scale
          );
          const latlngNW = projection.fromPointToLatLng(worldCoordNW);
          
          const worldCoordSE = new window.google.maps.Point(
            (pixelcoordX + 512) / scale,
            (pixelcoordY + 512) / scale
          );
          const latlngSE = projection.fromPointToLatLng(worldCoordSE);

          if (fitBounds.length != 0) {
            const minLat = fitBounds[0][1];
            const maxLat = fitBounds[1][1];
            const minLng = fitBounds[0][0];
            const maxLng = fitBounds[1][0];
            if ((latlngNW.lng() < minLng && latlngNW.lat() > maxLat)
              && (latlngSE.lng() > maxLng && latlngNW.lat() > maxLat)
              && (latlngSE.lng() > maxLng && latlngSE.lat() < minLat)
              && (latlngNW.lng() < minLng && latlngSE.lat() > minLat)) {
                // console.log("内包している")
            } else if ((latlngNW.lng() > minLng && latlngNW.lat() < maxLat)
              && (latlngSE.lng() < maxLng && latlngNW.lat() < maxLat)
              && (latlngSE.lng() < maxLng && latlngSE.lat() > minLat)
              && (latlngNW.lng() > minLng && latlngSE.lat() > minLat)) {
                // console.log("覆われている")
            } else if (!(latlngNW.lng() > maxLng || latlngSE.lng() < minLng 
              || latlngSE.lat() > maxLat || latlngNW.lat() < minLat)) {
                // console.log("交差している")
            } else {
              // console.log("タイルと接しない")
              return;
            }
          }

          return baseUrl + "gakku/emphasistile" + '?zoom={z}&nwLat={nwLat}&nwLng={nwLng}&neLat={neLat}&neLng={neLng}&seLat={seLat}&seLng={seLng}&swLat={swLat}&swLng={swLng}&pCoordX={pCoordX}&pCoordY={pCoordY}&schoolType={schoolType}&schoolCode={selectSchoolCode}'
            .replace('{z}',zoom)
            .replace('{nwLat}',latlngNW.lat()).replace('{nwLng}',latlngNW.lng())
            .replace('{seLat}',latlngSE.lat()).replace('{seLng}',latlngSE.lng())
            .replace('{pCoordX}',coord.x).replace('{pCoordY}',coord.y)
            .replace('{schoolType}',schoolType)
            .replace('{selectSchoolCode}',schoolCode.join(','));
        },
        tileSize: new window.google.maps.Size(512, 512),
        maxZoom: 18,
        minZoom: 6,
        opacity: 1.0
      });
      
      map.overlayMapTypes.push(emphasisTile);

      this.dispEmphasisTile = true;
      
      return emphasisTile;
    },
    getSchoolzoneTile(school) {   //学区タイル取得
      var mapTileApi = null;
      if (school == 'es') {
        mapTileApi = '/map/tms/1.0.0/cds_atgk_school_zone_es/webmercator/';
      } else if (school == 'jhs') {
        mapTileApi = '/map/tms/1.0.0/cds_atgk_school_zone_jhs/webmercator/';
      } else {
        return;
      }
      const esTile = new window.google.maps.ImageMapType({
        getTileUrl: function(coord, zoom) {
          if (zoom < LAYER_MIN_ZOOM) return;
          var ymax = 1 << zoom;
          var Y = ymax - coord.y - 1;
          return baseUrl + mapTileApi +
            '{z}/{x}/{y}.png'.replace('{z}',zoom-1).replace('{x}',coord.x).replace('{y}',Y);
        },
        tileSize: new window.google.maps.Size(256, 256),
        maxZoom: 18,
        minZoom: LAYER_MIN_ZOOM,
        opacity: 0.8
      });
      map.overlayMapTypes.insertAt(0, esTile);
    },
    getSchoolPointTile(school) {    //学校ポイントタイル取得
      var mapTileApi = null;
      if (school == 'es') {
        mapTileApi = '/map/tms/1.0.0/cds_atgk_school_point_es/webmercator/';
      } else if (school == 'jhs') {
        mapTileApi = '/map/tms/1.0.0/cds_atgk_school_point_jhs/webmercator/';
      } else {
        return;
      }
      const esTile = new window.google.maps.ImageMapType({
        getTileUrl: function(coord, zoom){
          if (zoom < 13) return;
          var ymax = 1 << zoom;
          var Y = ymax - coord.y - 1;
          return baseUrl + mapTileApi + '{z}/{x}/{y}.png'.replace('{z}',zoom-1).replace('{x}',coord.x).replace('{y}',Y);
        },
        tileSize: new window.google.maps.Size(256, 256),
        maxZoom: 18,
        minZoom: 13,
      });
      map.overlayMapTypes.insertAt(1, esTile);
    },
    getSchoolInfo(school, lat, lon, zoom){    // REST-APIの実行処理
      var fitBounds = [[0, 0], [0, 0]];
      this.isLoading = true;
      axios.get('gakku/parea' + '?school=' + school + '&lat=' + lat + '&lon=' + lon + '&zoom=' + zoom)
        .then(response => {
            if ((response.status != '200') || ((response.status == 200) && (response.data.code != 200))) {
              if (response.data.code == 999) {
                this.openApiError(`予期せぬエラーが発生しました`);
              } else {
                this.openApiError(`しばらく待ってから再度お試しください`);
              }
            } else if ((response.data.count_zone == 0) &&  (response.data.count_point > 0)) {
              // 学区なし、学校ポイントあり
              for (let i = 0; i < response.data.count_point; i++) {
                var text = null
                var position = new window.google.maps.LatLng(
                  response.data.data[i].point[1],
                  response.data.data[i].point[0])
                this.setSchoolPoint(position, school);
                text = '詳細については自治体へお問い合わせください。' + '<br>'
                text += '学校名：' + response.data.data[i].school_name + '<br>';
                text += '所在地：' + response.data.data[i].address;
                this.setInfoWindow(position, text);
              }
            } else if (( response.data.count_zone > 0) && (response.data.count_point > 0)) {
              // 学区あり、学校ポイントあり
              // ポリゴンとfitBoundsの設定
              var schoolCode = [];
              var max_lat = 0.0, max_lon = 0.0;
              var min_lat = 200.0, min_lon = 200.0;
              for (let i = 0; i < response.data.count_zone; i++) {
                schoolCode.push(response.data.data[i].school_code);
                if (response.data.data[i].maxLat && max_lat < response.data.data[i].maxLat) {
                  max_lat = response.data.data[i].maxLat;
                }
                if (response.data.data[i].minLat && min_lat > response.data.data[i].minLat) {
                  min_lat = response.data.data[i].minLat;
                }
                if (response.data.data[i].maxLng && max_lon < response.data.data[i].maxLng) {
                  max_lon = response.data.data[i].maxLng;
                }
                if (response.data.data[i].minLng && min_lon > response.data.data[i].minLng) {
                  min_lon = response.data.data[i].minLng;
                }
              }
              fitBounds[0][1] = min_lat;
              fitBounds[1][1] = max_lat;
              fitBounds[0][0] = min_lon;
              fitBounds[1][0] = max_lon;

              this.setEmphasisTile(school, schoolCode, fitBounds);
              var bounds = new window.google.maps.LatLngBounds();
              bounds.extend (new window.google.maps.LatLng(
                fitBounds[0][1], 
                fitBounds[0][0]));
              bounds.extend (new window.google.maps.LatLng(
                fitBounds[1][1],
                fitBounds[1][0]));
              // 学校ポイントと吹き出しの表示
              for (let i = 0; i < response.data.count_point; i++) {
                position = new window.google.maps.LatLng(
                  response.data.data[i].point[1],
                  response.data.data[i].point[0])
                this.setSchoolPoint(position, school);
                text = '学校名：' + response.data.data[i].school_name + '<br>';
                text += '所在地：' + response.data.data[i].address;
                this.setInfoWindow(position, text);
              }
              map.fitBounds (bounds);
            }
            this.isLoading = false;
          })
          .catch(function() {
            this.openApiError(`しばらく待ってから再度お試しください`);
          }.bind(this));
    },
    addOverlayClickEvent(school, overlay) {   // 地図上に配置されたOverlayをクリックした時のイベント
      overlay.addListener('click', function(event) {
        this.mapClickEvent(school, event.latLng.lat(), event.latLng.lng());
      }.bind(this));              
    },
    addMapClickEvent(school) {    // 地図をクリックした時のイベント
      eventListener = map.addListener('click', function (event) {
        this.mapClickEvent(school, event.latLng.lat(), event.latLng.lng());
      }.bind(this));
    },
    mapClickEvent(school, lat, lng) {
        var zoom = map.getZoom();
        if (zoom < LAYER_MIN_ZOOM) return;
        this.clearInfoWindow();
        this.clearSchoolPoint();
        this.clearPolygon();
        // 強調表示ありの場合
        if (this.dispEmphasisTile) {
          // 末尾のOverlayMapType（強調表示タイル）を削除
          map.overlayMapTypes.removeAt(map.overlayMapTypes.getLength() - 1);
          this.dispEmphasisTile = false;
        }
        if(school == 'none') return
        this.getSchoolInfo(school, lat, lng, zoom);
    },
    getOshirase(event){
      var errorMessage = null;
      this.isLoading = true;
      this.setInertWindow(true);
      // 自動更新判定用に更新前のcookieを取得する
      const before_cookie = document.cookie;
      this.oshiraseData = [];
      axios.get('gakku/get-oshirase')
      .then(response => {
        this.setInertWindow(false);
        this.isLoading = false;
        if (response.status != 200) {
          this.oshiraseDialog = true;
          this.oshiraseContentsList = false;
          this.oshiraseShosai = false;
          this.oshiraseContentsZero = false;
          errorMessage = `予期せぬエラーが発生しました`;
        } else if (response.data.status != 200) {
          this.oshiraseDialog = true;
          this.oshiraseContentsList = false;
          this.oshiraseShosai = false;
          this.oshiraseContentsZero = false;
          errorMessage = `お知らせ一覧が取得できませんでした`;
        } else {
          // 利用要領同意はevent==nullで呼ばれる
          // ボタン押下で呼ばれた場合は自動表示の判定を行わない
          if (event == null) {
            // お知らせ一覧の取得前後のcookieを比較する
            if(this.checkAutoDisplay(before_cookie, document.cookie) == false) {
              return;
            }
          }
          var oshiraseData = null;
          if ('oshirase_data' in response.data) {
            oshiraseData = response.data.oshirase_data;
            if (oshiraseData.length < 1) {
              this.oshiraseDialog = true;
              this.oshiraseContentsList = false;
              this.oshiraseShosai = false;
              this.oshiraseContentsZero = true;
              return;
            }
          } else {
            this.oshiraseDialog = true;
            this.oshiraseContentsList = false;
            this.oshiraseShosai = false;
            this.oshiraseContentsZero = true;
            return;
          }
          const todayFull = new Date();
          const year = todayFull.getFullYear();
          const month = todayFull.getMonth();
          const day = todayFull.getDate();
          const today = new Date(year, month, day);
          for (let i = 0; i < oshiraseData.length; i++) {
            var dateStr = oshiraseData[i].oshirase_date;
            dateStr = this.strInsert(dateStr, 4, '/');
            dateStr = this.strInsert(dateStr, 7, '/');
            oshiraseData[i].oshirase_date = dateStr;
            var entry = new Date(dateStr);
            if (today <= entry.setDate(entry.getDate() + 30)) {
              // 登録後30日以内のお知らせには赤文字で"【NEW】"を付与する
              oshiraseData[i].oshirase_title = '<font color="#ff0000"><strong>【NEW】</strong></font>' + oshiraseData[i].oshirase_title;
            }
          }
          this.oshiraseData = oshiraseData;
          this.oshiraseDialog = true;
          this.oshiraseShosai = false;
          this.oshiraseContentsZero = false;
          this.oshiraseContentsList = true;
        }
      })
      .catch(function() {
        this.isLoading = false;
        this.oshiraseDialog = true;
        this.oshiraseContentsList = false;
        this.oshiraseShosai = false;
        this.oshiraseContentsZero = false;
        errorMessage = `予期せぬエラーが発生しました`;
      }.bind(this))
      .finally(function() {
        // finallyでエラーの表示を行う
        // エラー発生時に処理が同期されずに、エラー表示の後にお知らせダイアログが表示される。
        // エラー表示をfinallyで行う事で処理を同期させる。
        if (errorMessage != null){
          this.openApiError(errorMessage);
        }
      }.bind(this));

    },
    strInsert(str, idx, val) {
      var res = str.slice(0, idx) + val + str.slice(idx);
      return res;
    },
    checkAutoDisplay(beforeCookie, currentCookie){
      var oshiraseBefore = this.getTargetCookie(beforeCookie, 'oshirase');
      var oshiraseCurrent = this.getTargetCookie(currentCookie, 'oshirase');

      // cookieの比較
      if (!oshiraseBefore) {
        // お知らせのcookieが存在しない時は自動表示
        return true;
      }
      const beforeKeys = Object.keys(oshiraseBefore);
      for (var key of beforeKeys) {
        if (key in oshiraseCurrent) {
          if (oshiraseBefore[key] == oshiraseCurrent[key]) {
            delete oshiraseBefore[key];
            delete oshiraseCurrent[key];
          } else {
            delete oshiraseBefore[key];
          }
        }
      }
      // 不一致があった時に自動表示を行う
      if (Object.keys(oshiraseBefore).length != Object.keys(oshiraseCurrent).length) {
        return true;
      }
      return false;
    },
    getTargetCookie(cookie, target){
      let matchPos = cookie.indexOf(target + '=');
      if (matchPos == -1) {
        return null;
      }
      const startIndex = matchPos + (target + '=').length;
      let endIndex = cookie.indexOf( ';', startIndex );
      // 末尾には";"がつかないため見つからない時はindexをcookieの末尾にする
      if (endIndex == -1) {
        endIndex = cookie.length;
      }
      // キーのみで値がない時
      if (startIndex == endIndex) {
        return null;
      }
      return JSON.parse(decodeURIComponent(cookie.substring(startIndex, endIndex)));
    },
    getshosai(item) {
      this.isLoading = true;
      this.setInertWindow(true);
      this.oshiraseShosaiMsg = '';
      var errorMessage = null;
      axios.get('gakku/get-oshirase/shosai'+ '?id=' + item.oshirase_id)
        .then(response => {
          // this.oshiraseContentsList = false;
          // this.oshiraseShosai = true;
          if (response.status != 200) {
            errorMessage = `予期せぬエラーが発生しました`;
          } else if (response.data.status != 200) {
            errorMessage = `お知らせ詳細が取得できませんでした`;
          } else {
            if ("oshirase_shosai" in response.data) {
              this.setInertWindow(false);
              this.isLoading = false;
              this.oshiraseContentsList = false;
              this.oshiraseShosai = true;
              this.oshiraseContentsZero = false;
              this.oshiraseShosaiMsg = response.data.oshirase_shosai;
              this.oshiraseTitle = response.data.oshirase_title;
            } else {
              errorMessage = `お知らせ詳細が取得できませんでした`;
            }
          }
        })
        .catch(function () {
          // this.setInertWindow(false);
          this.isLoading = false;
          // this.oshiraseShosai = false;
          // this.oshiraseContentsList = true;
          errorMessage = `予期せぬエラーが発生しました`;
        }.bind(this))
        .finally(function () {
          if (errorMessage != null) {
            this.openApiError(errorMessage);
          }
        }.bind(this));
    },
    oshiraseBackToList(){
      this.oshiraseShosai = false;
      this.oshiraseContentsZero = false;
      this.oshiraseContentsList = true;
      this.oshiraseTitle = 'お知らせ';
    },
    closeOshirase(){
      this.oshiraseDialog = false;
    },
    closeOshiraseError(){
      if (this.oshiraseContentsList == true) {
        return;
      }
      if (this.oshiraseDialog == true) {
        this.closeOshirase();
      }
    }
  },
};
</script>


<style scoped>
  .toolbar {
    border-bottom: 3px solid #b9b9b9;
    overflow-x: auto;
    height:66.4px;
  }
  .title-icon {
    width:48px;
    margin-left:10px;
  }

  .toolbar-title {
    white-space: inherit;
    min-width: 350px;
    margin-left: 16px;
    font-size:20px;
  }

  .beta {
    overflow: hidden;
    white-space: nowrap;
    display: inline;
    color: #ffffff;
    border-radius: 6px;
    background-color: #fbaf7f;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 16px;
    padding-left: 16px;
  }
  
  .develop {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width:340px;
    display: inline;
    font-size:16px;
  }
  .switch-menu-btn {
    background-color: #000000;
    color:#ffffff;
    font-size:18px;
    text-align: center;
  } 

  .switch-menu-btn.show {
    position: absolute;
    right: 0px;
    top: 0px;
    min-width:40px;
    height: 30px;
    padding-top: 2px;
    cursor: pointer;
  }
  .switch-menu-btn.hide {
    display: block;
    padding: 2px 2px 2px;
  }

  .switch-menu-title {
    display:block;
    padding:5px 10px 5px;
    font-size: 14px; 
    color: #ffffff;
    background-color: #808080;
  }

  
  .map-content {
    position: absolute;
    top: 66.4px;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .map {
    position:relative;
    transition: width .3s;
    height: 100%;
  }
  .switch-menu {
    position: absolute;
    transition: width .3s;
    top: 0px;
    right: 0px;
    height: 100%;
  }
  .switch-menu-area1 {
    height: 100%;
    width:260px;
  }

  .switch-menu-area2 {
    cursor: pointer;
  }
  .switch-menu-visible-btn{
    color: #ffffff;
  }

  .switch-menu-btn {
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
  }
  .switch-menu-subtitle1{
    padding:5px 10px 5px;
    display:block;
    font-size: 14px;
    font-weight:700;
    color: white;
    background-color: #33CCCC;
  }
  .switch-menu-scroll {
    overflow-y: auto;
    height: calc(100% - 31px);
  }

  .radio-item-label {
    color: #000000;
    font-size: 12px;
    padding-bottom: 2px;
  }
  .legends-block1 {
    height: calc(100% - 173px);
    display: flex;
    min-height: 280px;
    align-items: flex-end;
  }
  .legends-block2 {
    bottom:0;
    margin: 0px 0px 10px 8px;
  }
  .legends-title{
    font-size: 14px;
    margin-bottom: 3px;
  }

  .legends-es-margin {
    margin: 5px 0px 5px 3px;
  }
  .legends-jhs-margin {
    margin: 5px 0px 5px 3px;
  } 

  .search-window {
    margin: '10px 0 0 10px';
    width: 400px;
  }
  .search-textfield.v-text-field >>> .v-field__field {
    width: 400px;
  }
  .search-textfield.v-text-field >>> .v-field__input {
    padding: 0;
  }
  .search-textfield.v-text-field >>> input {
    padding: 7px 36px 7px 16px;
    margin: 0;
  }
  .annotation-window {
    width: 90%;
  }
  .annotation-closebtn-area {
    cursor: pointer;
    margin: 10px 15px 0px 0px;
    padding: 3px 3px 0px 0px;
  }
  .annotation-closebtn {
    font-size: 20px;
    color: #000000;
  }
  .annotation-text {
    padding: 0px 25px 10px 25px;
    color: #ff0000;
    font-size: 12px;
  }
  .scale-error-window {
    white-space: pre-line;
    opacity:0.9;
    text-align: center;
    width:630px;
  }
  .scale-error-closebtn-area {
    height:4px;
  }
  .scale-error-closebtn-pointer {
    cursor: pointer;
  }
  .scale-error-closebtn-size{
    font-size: 20px;
  }
  .scale-error-msg-area {
    height:100px;
  }
  .scale-error-msg {
    margin: 5px 2px 5px 2px;
    color: #ff0000;
    font-size: 24px;
    line-height: 60px;
  }
  .api-error-area {
    padding: 5px 5px 5px 5px;
  }
  .api-error-msg-area {
    padding: 5px 0 0 15px !important;
  }
  .api-error-msg {
    color:#ff0000;
    font-size:20px;
  }
  .legends-icon {
    width:36px;
    margin-left:5px;
  }
  .legends-icon-label {
    font-size: 12px;
    padding-left: 15px;
  }

  .legends-label {
    font-size: 12px;
    padding-left: 7px;
  }

  .rectangle-es {
    width: 50px;
    height: 25px;
    border: 2px solid rgba(0,0,255,0.8);
  }
  .rectangle-es.info-area{
    background-color: rgba(0,0,255,0.1);
  }
  .rectangle-es.selected-area{
    background-color: rgba(223,86,86,0.25);
  }
  .rectangle-es.unknown-area{
    background-color: rgba(0,0,255,0.2);
  }
  .rectangle-jhs {
    width: 50px;
    height: 25px;
    border: 2px solid rgba(128,0,128,0.8);
  }

  .rectangle-jhs.info-area{
    background-color: rgba(128, 0, 128, 0.15);
  }
  .rectangle-jhs.selected-area{
    background-color: rgba(223,86,86,0.25);
  }
  .rectangle-jhs.unknown-area{
    background-color: rgba(128, 0, 128, 0.3);
  }

  .legends-annotaion{
    font-size: 11px;
    margin:8px 0px 0px 0px;
    white-space: pre-line;
    text-indent: -1em;
    padding-left: 1em;
  }
  .oshirase-list{
    overflow:hidden;
    width: 1150px;
  }
  .oshirase-title{
    height: 36px;
    margin: 8px 10px 0px 10px;
    padding: 2px 0px 2px 12px;
    font-size: 16px;
    font-weight:600;
    color:#000000;
    background-color: #eeeeee
  }
  .oshirase-close-btn{
    margin: 0px 12px 18px 0px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    background-color: #000000;
    border: 2px solid #ffffff;
  }
  .oshirase-area-scroll{
    overflow-x: auto;
    overflow-y: auto;
  }
  .oshirase-area-scroll :deep(img){
    max-width: 100%;
  }
  .oshirase-btn{
    height:40px;
    margin-top:3px;
    color: #00568a;
    border: 1px solid #b3ccdc;
  }
  .back-list{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .oshirase-zero-area{
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .oshirase-zero{
    font-size: 24px;
    font-weight: 600;
  }
  .oshirase-shosai-area{
    margin: 7px;
    padding:7px;
  }
  .oshirase-date{
    font-size: 15px;
    width: 120px;
    background: #d9e9ea;
    border: 1px solid #d9e9ea;
    border-radius: 15px;
    text-align: center;
    margin-left: 15px;
    top: 15px;
  }
  table td {
    font-size: 14px;
    background: #ffffff;
    padding: 10px 12px !important;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: left;
    border-bottom: 1px solid #d1d1d1;
  }
  table tr:nth-child(even) td {
    background: #fafafa;
  }
  /* tbody {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100px;
  } */

  ::-webkit-scrollbar{
    width: 10px;
    height: 5px;
  }
  ::-webkit-scrollbar-track{
    background: rgba(0,0,0,0);
    border: none;
  }
  ::-webkit-scrollbar-thumb{
    background: rgba(224,224,224,0.5);
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(150, 150, 150, 0.5);
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(224,224,224,0.5) rgba(0,0,0,0);
}

</style>