<template>
  
  <div id="modal">
    <v-row justify="center">
      <v-dialog 
        v-model="dialog" persistent width="auto">
        <v-card width="650px" height="550px">
          <v-card-title class="kiyaku-title">{{kiyakuTitle}}</v-card-title>
          <v-divider></v-divider>
          <v-card-title class="kiyaku-subtitle">{{kiyakuSubtitle}}</v-card-title>
          <v-card-text class="kiyaku-annotation">
            利用要領を最下部までスクロール頂き、利用要領に同意の上サービスをご利用ください。
          </v-card-text>
          <v-row justify="center">
            <v-card flat class="kiyaku-scroll-area" width="600px" height="270px" color="grey-lighten-4" v-scroll.self="onScroll" >
              <v-card-text class="kiyaku-msg" id="scroll-target" >
                {{kiyakuMessage}}
              </v-card-text>
            </v-card>
          </v-row>
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                    class="d-flex justify-center"
                    v-model="isChecked"
                    v-bind:disabled="isNotScrolled">
                  <template v-slot:label>
                    <p id="kiyaku-agree">利用要領に同意する</p>
                 </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12" >
                <p class="d-flex justify-center pb-5">上記、利用要領に同意の上サービスを利用します。</p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  v-bind:color="isChecked ? 'orange-darken-3':''"
                  variant="flat"
                  v-bind:disabled="!(isChecked)"
                  @click="returnTrue">サービスを利用する</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div class="modal-overlay"></div>
  </div>
</template>
  
<script>
  export default {
    name: "Modal",
    props: ["kiyakuTitle", "kiyakuSubtitle", "kiyakuMessage"],
    data() {
      return {
        dialog: true,
        isChecked: false,     // 「利用要領に同意する」のチェックがされているか
        isNotScrolled: true,  // スクロール判定（初期値は一番下までスクロールされていない）
      }
    },
    mounted() {
    },
    methods: {
      returnTrue() {
        this.dialog = false;
        this.$emit("initial-method", true);
      },
      onScroll (event){
        // 一番下までスクロールしたか判定する
        const offset = 1;
        const scrollElm = document.getElementById('scroll-target');
        const agreement = document.getElementById('kiyaku-agree');
        if (event.target.clientHeight + event.target.scrollTop + offset >= scrollElm.clientHeight) {
            this.isNotScrolled = false;
            agreement.style.color="#000000";
            agreement.style.fontWeight="600";
        }
      }
    },
  };
</script>

<style scoped>
.kiyaku-title{
  margin: 4px 0px 0px 8px;
  font-size: 18px;
  font-weight:600;
}
.kiyaku-subtitle {
  margin: 4px 0px 8px 8px;
  font-size: 16px;
  font-weight:600;
}
.kiyaku-annotation {
  margin: -30px 5px 10px 5px;
  font-size: 14px !important;
  font-weight:600;
}
.kiyaku-scroll-area{
  border-width: 1px;
  overflow-y: auto;
}
.kiyaku-msg {
  white-space: pre-line;
  font-size: 14px;
}
div::-webkit-scrollbar {
    width: 5px;
}
div::-webkit-scrollbar-track {
   background: #F5F5F5;
}

div::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius:5px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #E0E0E0 #F5F5F5;
}

.modal-overlay {
  display: block;
  position: fixed;
  top: 66.4px;
  left: 0;
  width: 100%;
  height: 120%;
  backdrop-filter: blur(12px);
}
</style>